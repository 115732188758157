import topPic from '../assets/images/business/education/top.jpg';
import infoImage1 from '../assets/images/business/education/info-1.png';
import infoImage2 from '../assets/images/business/education/info-1.jpg';

export default {
  headerIndex: 1,
  childIndex: 2,
  topPic,
  title: '教育培训中心',
  info: '基于“赋能云平台”，积极发展“互联网+教育”，切实培养中国煤炭行业人才，打通各终端，提供全场景自主式教育培训平台',
  breadcrumb: ['首页 >', '主要业务 >', '教育培训中心'],
  infoImage1,
  infoImage2,
  pItems1: [
    '千沐科技为贯彻落实习近平总书记关于人才工作的重要论述和全国教育大会精神，加快推进教育信息化高质量发展，积极发展“互联网+教育”，切实培养中国煤炭行业人才，建设和运营了“中国煤炭教育赋能·云平台”（以下简称“赋能云平台”）。以1+2形式（赋能云平台网站+赋能云职道/赋能云课堂小程序）打通各终端，提供全场景自主式教育培训平台。',
  ],
  pItems2: [
    '赋能云平台于2019年9月正式发布上线，运营近两年来，千沐科技不断以大数据技术为基础，对赋能云平台进行煤炭行业职业赋能课程体系建设，完成覆盖行业 20 余板块，万余课时课程的上线。现已实现在线导入学号近百万，为在校学生提供职业技能及矿业行业认证课程学习和实训。',
    '同时，赋能云平台使用大数据记录其行为过程和档案，将学生基本信息和其在平台的赋能行为进行数据化、标签化，并对数据进行关联、建库，开展智能化、个性化赋能服务。学生可借助平台在线学习专业课程、认证课程，并在完成相应课程后在线申请证书认证。',
  ],
};
