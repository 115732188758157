import topPic from '../assets/images/business/solution/top.jpg';
import infoImage1 from '../assets/images/business/solution/info-1.png';
import infoImage2 from '../assets/images/business/solution/info-2.jpg';
import infoImage3 from '../assets/images/business/solution/info-3.jpg';

export default {
  headerIndex: 1,
  childIndex: 1,
  topPic,
  title: '解决方案中心',
  info: '紧贴智能化趋势，以客户实际需求为起点，针对智能矿山及智慧校园提供贴合行业实际需求的解决方案',
  breadcrumb: ['首页 >', '主要业务 >', '解决方案中心'],
  infoImage1,
  infoImage2,
  infoImage3,
  pItems1: [
    '紧贴煤矿智能化趋势，以煤炭行业实际需求为起点，将人工智能、工业物联网、云计算、大数据、机器人、5G通信技术等与现代煤炭开发利用深度融合作为核心技术支撑，形成全面感知、实时互联、分析决策、自主学习、动态预测、协同控制的煤矿综合解决方案，实现煤矿综采、掘进、运输、通风、洗选、安全保障、经营管理等过程的智能化运行，对于提升煤矿安全生产水平、保障煤炭稳定供应具有重要意义。',
  ],
  pItems2: [
    '解决方案中心依托智能矿山研究中心的最新研究成果，在针对煤矿生产智能优化、安全智能优化等方面提供了许多贴合行业实际需求的解决方案，其中千沐科技与中国科学研究院上海高等研究院共同打造的“智能化煤矿综合管控平台”利用“AI+IoT+一网统管”、“智能巡屏”等方式构建了多场景、一站式智能煤矿解决方案，实现了AI研判处置全闭环管理，成功在多个项目中实际应用并取得了优异的表现。',
  ],
  pItems3: [
    '解决方案中心依托智能矿山研究中心的最新研究成果，在针对煤矿生产智能优化、安全智能优化等方面提供了许多贴合行业实际需求的解决方案，其中千沐科技与中国科学研究院上海高等研究院共同打造的“智能化煤矿综合管控平台”利用“AI+IoT+一网统管”、“智能巡屏”等方式构建了多场景、一站式智能煤矿解决方案，实现了AI研判处置全闭环管理，成功在多个项目中实际应用并取得了优异的表现。',
  ],
};
