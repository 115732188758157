<template>
  <div class="pic-intro">
    <div class="inner">
      <img :src="pic" alt="介绍" v-if="imgInLeft" />
      <div class="pic-intro-info">
        <p v-for="(item, index) in pItems" :key="index">
          {{ item }}
        </p>
      </div>
      <img :src="pic" alt="介绍" v-if="!imgInLeft" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PicInfo',
  props: {
    pic: String,
    pItems: Array,
    imgInLeft: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
};
</script>
<style scoped lang="less">
.pic-intro {
  width: 100%;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  .inner {
    width: @size1200;
    min-width: 600px;
    display: flex;
    justify-content: space-between; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    img {
      width: @size580;
      min-width: 290px;
      height: auto;
    }
    .pic-intro-info {
      color: #3e3a39;
      line-height: 1.5;
      font-size: @font18;
      text-align: left;
      width: @size580;
      min-width: 290px;
      p {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
