<template>
  <div class="services">
    <div class="inner">
      <div class="item" v-for="(item, index) in items" :key="index">
        <img :src="item.img" alt="背景" />
        <div class="info-wrapper">
          <div class="info-title">{{ item.title }}</div>
          <div class="info-content">{{ item.info }}</div>
        </div>
        <div class="more" @click="goto(item)">了解更多</div>
      </div>
    </div>
  </div>
</template>

<script>
import { routeUrls } from '../utils/constants';
import service1 from '../assets/images/business/solution/service-1.jpg';
import service2 from '../assets/images/business/solution/service-2.jpg';
import service3 from '../assets/images/business/solution/service-3.jpg';
import service4 from '../assets/images/business/solution/service-4.jpg';

export default {
  name: 'ServiceCards',
  data() {
    return {
      items: [
        {
          title: '智能矿山解决方案',
          info: '致力于提升矿山智能化水平，以科技进步推动煤矿安全及高效生产',
          img: service1,
          url: routeUrls.mineSolution,
        },
        {
          title: '智慧校园解决方案',
          info: '夯实学校信息基础与智能管理，进一步提升校园数字化水平',
          img: service2,
          url: routeUrls.campusSolution,
        },
        {
          title: '人工智能产教融合',
          info: '以创新实训助力智能化人才培养，打造完整AI产教融合体系',
          img: service3,
          url: routeUrls.aiSolution,
        },
        {
          title: '数字安全体系系统',
          info: '助力企业实现业务的数字身份，为企业全程电子化及网络安全保驾护航',
          img: service4,
          url: routeUrls.systemSolution,
        },
      ],
    };
  },
  methods: {
    goto(item) {
      const { url } = item;
      if (url) {
        this.$router.push({ path: url });
      }
    },
  },
};
</script>
<style scoped lang="less">
.services {
  width: 100%;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  .inner {
    width: @size1200;
    min-width: 600px;
    display: flex;
    justify-content: space-between; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    flex-wrap: wrap;
  }
  .item {
    width: @size580;
    min-width: 290px;
    height: @size300;
    min-height: 150px;
    border-radius: @size10;
    color: #fff;
    position: relative;
    overflow: hidden;
    margin-bottom: @size40;
    img {
      width: 100%;
      height: auto;
    }
    .info-wrapper {
      position: absolute;
      top: @size40;
      left: @size40;
      width: @size300;
      min-width: 150px;
      height: @size300;
      min-height: 150px;
      text-align: left;
      color: #fff;
      z-index: 10;
      .info-title {
        font-size: @size32;
        font-weight: 700;
        margin-bottom: @size40;
      }
      .info-content {
        font-size: @font16;
        line-height: 1.875;
      }
    }
    .more {
      position: absolute;
      right: @size30;
      bottom: @size30;
      z-index: 10;
      cursor: pointer;
      width: @size160;
      min-width: 80px;
      height: @size50;
      min-height: 25px;
      font-size: @size20;
      line-height: @size50;
      color: #0066dd;
      border-radius: @size25;
      font-weight: 700;
      background: #fff;
    }
  }
}
</style>
