<template>
  <div class="consultation-center">
    <Header
      theme="white"
      :currentIndex="headerIndex"
      :childIndex="childIndex"
    />
    <TopPic :img="topPic" :title="title" :info="info" :breadcrumb="breadcrumb" />
    <div class="info-wrapper">
      {{ content }}
    </div>
    <div class="pic-wrapper">
      <img :src="infoImage" alt="" />
    </div>
    <Consult />
    <Footer />
    <div class="footer"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import TopPic from '@/components/TopPic.vue';
import Consult from '@/components/Consult.vue';
import config from '../../utils/consultationCenter';

export default {
  name: 'MineResearchCenter',
  components: {
    Header,
    Footer,
    TopPic,
    Consult,
  },
  data() {
    return {
      ...config,
    };
  },
};
</script>
<style lang="less" scoped>
.info-wrapper {
  padding-left: @size460;
  padding-right: @size440;
  padding-top: @size140;
  padding-bottom: @size80;
  background: #f5f5f5;
  text-align: left;
  font-size: @size30;
  color: #3e3a39;
  line-height: 1.8;
}
.pic-wrapper {
  padding-left: @size460;
  padding-right: @size440;
  padding-bottom: @size140;
  background: #f5f5f5;
  img {
    width: 100%;
    height: auto;
  }
}
</style>
