import topPic from '../assets/images/business/consult/top.jpg';
import infoImage from '../assets/images/business/consult/info.png';

export default {
  headerIndex: 1,
  childIndex: 3,
  topPic,
  title: '技术咨询中心',
  info: '以智能矿山研究中心专家团队为基石，通过解决方案中心输出适合企业的具体解决方案，提供智能化改进的方向和技术咨询',
  breadcrumb: ['首页 >', '主要业务 >', '技术咨询中心'],
  infoImage,
  content:
    '该中心旨在为煤矿企业提供智能化技术咨询，加速煤矿企业智能化进程。以智能矿山研究中心专家团队为基石，通过解决方案中心输出适合企业的具体解决方案，提供智能化改进的方向和技术咨询。',

};
