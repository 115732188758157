<template>
  <div class="school-cards">
    <div class="title">服务院校</div>
    <div class="divider"></div>
    <div class="wrapper">
      <div class="inner">
        <el-carousel
          height="2.2rem"
          indicator-position="outside"
          :autoplay="false"
        >
          <el-carousel-item v-for="(item, index) in items" :key="index">
            <div class="item">
              <div
                class="child"
                v-for="(child, childIndex) in item"
                :key="childIndex"
              >
                <img :src="child.img" alt="" />
                <div class="school-name">
                  {{ child.title }}
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import xakj from '../assets/images/business/education/school/西安科技大学.png';
import ahlg from '../assets/images/business/education/school/安徽理工大学.png';
import hbgc from '../assets/images/business/education/school/河北工程大学.png';
import hblg from '../assets/images/business/education/school/河北理工大学.png';
import hnlg from '../assets/images/business/education/school/河南理工大学.png';
import nlgc from '../assets/images/business/education/school/辽宁工程技术大学.png';
import tylg from '../assets/images/business/education/school/太原理工大学.png';
import zgky from '../assets/images/business/education/school/中国矿业大学.png';
import zgkybj from '../assets/images/business/education/school/中国矿业大学-北京.png';
import ahhb from '../assets/images/business/education/school/安徽淮北煤电技师学院.png';
import cqgc from '../assets/images/business/education/school/重庆工程职业技术学院.png';
import hngc from '../assets/images/business/education/school/河南工程学院.png';
import hbkj from '../assets/images/business/education/school/华北科技学院.png';
import lzzy from '../assets/images/business/education/school/兰州资源环境职业技术学院.png';
import pds from '../assets/images/business/education/school/平顶山工业职业技术学院.png';
import sxny from '../assets/images/business/education/school/陕西能源职业技术学院.png';
import ahky from '../assets/images/business/education/school/安徽矿业职业技术学院.png';
import dtmt from '../assets/images/business/education/school/大同煤炭职业技术学院.png';
import gsny from '../assets/images/business/education/school/甘肃能源化工职业学院.png';
import hdgc from '../assets/images/business/education/school/邯郸工程高级技工学校.png';
import hbny from '../assets/images/business/education/school/河北能源职业技术学院.png';
import hbnyhg from '../assets/images/business/education/school/鹤壁能源化工职业学院.png';
import hgsf from '../assets/images/business/education/school/鹤岗师范高等专科学校.png';
import hljkj from '../assets/images/business/education/school/黑龙江科技大学.png';
import hljny from '../assets/images/business/education/school/黑龙江能源职业学院.png';
import hnkj from '../assets/images/business/education/school/湖南科技大学.png';
import hbkjxy from '../assets/images/business/education/school/花呗科技学院.png';
import hbsf from '../assets/images/business/education/school/淮北师范大学.png';
import hnzy from '../assets/images/business/education/school/淮南职业技术学院.png';
import lzzyhj from '../assets/images/business/education/school/兰州资源环境大学.png';
import ncgxy from '../assets/images/business/education/school/南昌工学院.png';
import nmgkj from '../assets/images/business/education/school/内蒙古科技大学.png';
import pzxy from '../assets/images/business/education/school/平职学院.png';
import sdgs from '../assets/images/business/education/school/山东工商学院.png';
import sdkj from '../assets/images/business/education/school/山东科技大学.png';
import sxdt from '../assets/images/business/education/school/山西大同大学.png';
import sjzgc from '../assets/images/business/education/school/石家庄工程技术学校.png';
import scsf from '../assets/images/business/education/school/四川师范大学.png';
import xakjdxgx from '../assets/images/business/education/school/西安科技大学高新学院.png';
import xjgc from '../assets/images/business/education/school/新疆工程学院.png';
import yczy from '../assets/images/business/education/school/运城职业技术大学.png';
// import zjkmk from '../assets/images/business/education/school/张家口煤矿机械制造技工学校.png';
import zzgy from '../assets/images/business/education/school/郑州工业技师学院.png';

export default {
  name: 'SchoolCards',
  props: {},
  computed: {
    items() {
      return this.sliceArray(this.schools, this.size);
    },
    height() {
      const width = Math.max(window.innerWidth, 960);
      return `${(220 / 1920) * width}px`;
    },
  },
  data() {
    return {
      size: 5,
      schools: [
        {
          title: '西安科技大学',
          img: xakj,
        },
        {
          title: '安徽理工大学',
          img: ahlg,
        },
        {
          title: '河北工程大学',
          img: hbgc,
        },
        {
          title: '华北理工大学',
          img: hblg,
        },
        {
          title: '河南理工大学',
          img: hnlg,
        },
        {
          title: '辽宁工程技术大学',
          img: nlgc,
        },
        {
          title: '太原理工大学',
          img: tylg,
        },
        {
          title: '中国矿业大学',
          img: zgky,
        },
        {
          title: '中国矿业大学（北京）',
          img: zgkybj,
        },
        {
          title: '安徽淮北煤电技师学院',
          img: ahhb,
        },
        {
          title: '重庆工程职业技术学院',
          img: cqgc,
        },
        {
          title: '河南工程学院',
          img: hngc,
        },
        {
          title: '华北科技学院',
          img: hbkj,
        },
        {
          title: '兰州资源环境职业技术学院',
          img: lzzy,
        },
        {
          title: '平顶山工业职业技术学院',
          img: pds,
        },
        {
          title: '陕西能源职业技术学院',
          img: sxny,
        },
        {
          title: '安徽矿业职业技术学院',
          img: ahky,
        },
        {
          title: '大同煤炭职业技术学院',
          img: dtmt,
        },
        {
          title: '甘肃能源化工职业学院',
          img: gsny,
        },
        {
          title: '邯郸工程高级技工学校',
          img: hdgc,
        },
        {
          title: '河北能源职业技术学院',
          img: hbny,
        },
        {
          title: '鹤壁能源化工职业学院',
          img: hbnyhg,
        },
        {
          title: '鹤岗师范高等专科学校',
          img: hgsf,
        },
        {
          title: '黑龙江科技大学',
          img: hljkj,
        },
        {
          title: '黑龙江能源职业学院',
          img: hljny,
        },
        {
          title: '湖南科技大学',
          img: hnkj,
        },
        {
          title: '花呗科技学院',
          img: hbkjxy,
        },
        {
          title: '淮北师范大学',
          img: hbsf,
        },
        {
          title: '淮南职业技术学院',
          img: hnzy,
        },
        {
          title: '兰州资源环境大学',
          img: lzzyhj,
        },
        {
          title: '南昌工学院',
          img: ncgxy,
        },
        {
          title: '内蒙古科技大学',
          img: nmgkj,
        },
        {
          title: '平职学院',
          img: pzxy,
        },
        {
          title: '山东工商学院',
          img: sdgs,
        },
        {
          title: '山东科技大学',
          img: sdkj,
        },
        {
          title: '山西大同大学',
          img: sxdt,
        },
        {
          title: '石家庄工程技术学校',
          img: sjzgc,
        },
        {
          title: '四川师范大学',
          img: scsf,
        },
        {
          title: '西安科技大学高新学院',
          img: xakjdxgx,
        },
        {
          title: '新疆工程学院',
          img: xjgc,
        },
        {
          title: '运城职业技术大学',
          img: yczy,
        },
        // {
        //   title: '张家口煤矿机械制造技工学校',
        //   img: zjkmk,
        // },
        {
          title: '郑州工业技师学院',
          img: zzgy,
        },
      ],
    };
  },
  methods: {
    sliceArray(arr, size) {
      const arr2 = [];
      let i = 0;
      while (i < arr.length) {
        arr2.push(arr.slice(i, i + size));
        i += size;
      }
      return arr2;
    },
  },
};
</script>
<style scoped lang="less">
.school-cards {
  background-image: url(../assets/images/business/education/school-bg.jpg);
  background-size: cover;
  height: @size700;
  min-height: 350px;
  width: 100%;
  overflow: hidden;
  position: relative;
  text-align: center;
  .title {
    margin-top: 102px;
    font-size: @size40;
    font-weight: 700;
    text-align: center;
    color: #666;
    margin-bottom: 30px;
  }
  .divider {
    display: inline-block;
    text-align: center;
    width: @size60;
    min-width: 30px;
    height: @size4;
    min-height: 2px;
    background: #0066dd;
  }
  .wrapper {
    width: 100%;
    margin-top: 110px;
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    .inner {
      width: @size1200;
      min-width: 600px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .item {
      width: @size1200;
      min-width: 600px;
      padding-left: @size120;
      padding-right: @size120;
      display: flex;
      justify-content: space-around;
      align-items: baseline;
      text-align: center;
      color: #666;
      .child {
        width: @size128;
        min-width: 64px;
        img {
          width: @size128;
          min-width: 64px;
        }
        .school-name {
          font-size: @size20;
          color: #3e3a39;
          margin-top: @size20;
        }
      }
    }
  }
}
</style>
<style lang="less">
.school-cards {
  .el-carousel--horizontal {
    width: 100%;
  }
  .el-carousel__indicator--horizontal {
    padding-top: @size100;
  }
  .el-carousel__indicators--outside button {
    width: @size120;
    min-width: 60px;
    height: @size10;
    min-height: 5px;
    border-radius: 5px;
  }
  .el-carousel__arrow {
    background: none;
    i {
      font-size: @size80;
      color: #939497;
      font-weight: 700;
    }
  }
  .is-active {
    .el-carousel__button {
      background: #fff;
    }
  }
}
</style>
