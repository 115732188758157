<template>
  <div class="mine-research-center">
    <Header theme="white" :currentIndex="headerIndex" :childIndex="childIndex" />
    <TopPic :img="topPic" :title="title" :info="info" :breadcrumb="breadcrumb" />
    <div class="pic-wrapper">
      <PicInfo :pic="infoImage" :pItems="pItems" />
    </div>
    <ThreeCards :cards="cards" />
    <PersonCards :personItems="personItems" style="display: none;"/>
    <MineServiceCard style="padding-top: 0;"/>
    <Consult />
    <Footer />
    <div class="footer"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import TopPic from '@/components/TopPic.vue';
import ThreeCards from '@/components/ThreeCards.vue';
import Consult from '@/components/Consult.vue';
import PicInfo from '@/components/PicInfo.vue';
import PersonCards from '@/components/PersonCards.vue';
import MineServiceCard from '@/components/MineServiceCard.vue';
import config from '../../utils/mineResearchCenter';

export default {
  name: 'MineResearchCenter',
  components: {
    Header,
    Footer,
    TopPic,
    ThreeCards,
    Consult,
    PicInfo,
    PersonCards,
    MineServiceCard,
  },
  data() {
    return {
      ...config,
    };
  },
};
</script>
<style lang="less" scoped>
.pic-wrapper {
  padding-top: @size60;
  padding-bottom: @size60;
}
</style>
