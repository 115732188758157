import topPic from '../assets/images/business/mine/top.jpg';
import card1 from '../assets/images/business/mine/card-1.jpg';
import card2 from '../assets/images/business/mine/card-2.jpg';
import card3 from '../assets/images/business/mine/card-3.jpg';
import infoImage from '../assets/images/business/mine/info.png';
import personImg from '../assets/images/business/mine/person.jpg';
import back1 from '../assets/images/service/mine/back-1.jpg';
import back2 from '../assets/images/service/mine/back-2.jpg';
import back3 from '../assets/images/service/mine/back-3.jpg';
// import { modalExtra } from './constants';

const cards = [
  {
    img: card1,
    title: '发展趋势',
    // extra: modalExtra,
    back: back1,
    info: '面对煤炭工业高质量发展的要求，和实现安全高效绿色开采和更加清洁高效利用的发展目标，八部委联合下发《关于加快煤矿智能化发展的指导意见》，对煤炭智能化建设提出了明确要求。',
  },
  {
    img: card2,
    title: '困境与挑战',
    info: '环境人员复杂、整体空间大、安全数据不直观，传统技术手段无法应对当前智能化新需求，管理人员无法及时掌握井下实时数据，难以针对发现的问题作出及时应对。',
    // extra: modalExtra,
    back: back2,
  },
  {
    img: card3,
    title: '应用',
    // extra: modalExtra,
    back: back3,
    info: '以井下5G融合通信为基础，以智能安全监控平台、综合管控平台为工具，进行采掘机运通全面智能化改造升级，提升工作效率，降低生产风险，确保人员安全。',
  },
];

const pItems = [
  '千沐科技联合中国科学院上海高等研究院、行业院校、云统科技等共同发起的新型研发机构。研究中心吸收了来自科技企业、科研机构、行业院校的众多专家组成了矿山智能化专家团队，并结合院校进行产教融合和产业落地，致力于机制体制创新、先试先行，打造多元共建、开放合作、产业协同、国内领先的新型研发机构，进一步推动煤矿智能化产业链迭代升级，探索应用驱动、科技引领、生态共融的新一代人工智能+煤炭创新发展模式，以科技进步助力煤矿智能化。',
  '在煤矿智能化技术创新方面，千沐科技携中科院上海高研院、行业院校、行业科研机构结合世界领先人工智能技术，为煤矿智能化带来更多的可能，现研究领域已覆盖智能安全、智能综合管控、智能装备、智能运输管理等，在基于机器视觉的煤矿智能化掘进系统、智能皮带运输检测系统、人员违规检测系统、无人自动驾驶系统等方面已取得丰硕成果。',
];

const personItems = [
  {
    img: personImg,
    title: '代用名',
    secondTitle: '中国矿业大学 教授',
    info: '环境人员复杂，整体空间大，环境复杂，入驻和到访的人员数量庞大，传统技术手段无法应对当前安保新需求。管理亟待提升，管理人员无法及时掌握出入人员数据，难以针对发现的问题作出及时应对。',
  },
  {
    img: personImg,
    title: '代用名',
    secondTitle: '中国矿业大学 教授',
    info: '环境人员复杂，整体空间大，环境复杂，入驻和到访的人员数量庞大，传统技术手段无法应对当前安保新需求。管理亟待提升，管理人员无法及时掌握出入人员数据，难以针对发现的问题作出及时应对。',
  },
];

export default {
  headerIndex: 1,
  childIndex: 0,
  title: '智能矿山研究中心',
  info: '千沐科技联合中国科学院上海高等研究院、行业院校等共同发起的新型研发机构，致力于机制体制创新、先试先行，以科技进步助力煤矿智能化。',
  breadcrumb: ['首页 >', '主要业务 >', '智能矿山研究中心'],
  pItems,
  topPic,
  infoImage,
  personImg,
  cards,
  personItems,
};
