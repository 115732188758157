<template>
  <div class="persons">
    <div class="title">领域专家</div>
    <div class="divider"></div>
    <div class="wrapper">
      <div class="inner">
        <div
          class="person-item"
          v-for="(item, index) in personItems"
          :key="index"
        >
          <img :src="item.img" alt="人物图片" />
          <div class="info-wrapper">
            <div class="info-title">{{ item.title }}</div>
            <div class="info-second-title">{{ item.secondTitle }}</div>
            <div class="info-content">{{ item.info }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PersonCards',
  props: {
    personItems: Array,
  },
};
</script>
<style scoped lang="less">
.persons {
  width: 100%;
  background: #f5f5f5;
  padding-top: @size50;
  .title {
    font-size: @size40;
    font-weight: 700;
    text-align: center;
    color: #666;
  }
  .divider {
    margin-top: @size30;
    width: @size60;
    min-width: 30px;
    height: @size4;
    min-height: 2px;
    background: #0066dd;
    display: inline-block;
  }
  .wrapper {
    display: flex;
    justify-content: center; /* 水平居中 */
    margin-top: @size48;
    .inner {
      width: 1200px;
      min-width: 600px;
      display: flex;
      justify-content: space-between;
      .person-item {
        width: @size585;
        min-width: 292px;
        // height: @size300;
        min-height: 150px;
        padding: @size30;
        position: relative;
        background: #fff;
        overflow: hidden;
        img {
          width: @size170;
          min-width: 85px;
          height: auto;
          float: left;
          margin-right: @size30;
        }
        .info-wrapper {
          text-align: left;
          color: #333333;
          .info-title {
            font-size: @size24;
            line-height: 1.25;
            margin-bottom: 10px;
          }
          .info-second-title {
            font-size: @font16;
            line-height: 1.5;
            margin-bottom: @size30;
          }
          .info-content {
            font-size: @font16;
            line-height: 1.5;
          }
        }
      }
    }
  }
}
</style>
