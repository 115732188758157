<template>
  <div class="mine-service-card">
    <div class="inner">
      <div class="title">智能矿山解决方案</div>
      <div class="info">
        为应对安全生产、管理效率低下、人员流失等诸多问题的挑战。
        顺应加快煤矿少人、无人化、开采智能化建设的行业发展趋势，
        千沐科技联合中科院上海高研院及行业院校共建共研，推出智慧矿山新型实用技术及行业解决方案。
      </div>
      <div class="more" @click="goto">了解更多</div>
    </div>
  </div>
</template>

<script>
import { routeUrls } from '../utils/constants';

export default {
  name: 'MineServiceCard',
  props: {},
  methods: {
    goto() {
      this.$router.push({ path: routeUrls.mineSolution });
    },
  },
};
</script>
<style scoped lang="less">
.mine-service-card {
  width: 100%;
  display: flex;
  justify-content: center; /* 水平居中 */
  background: #f5f5f5;
  padding-top: @size100;
  padding-bottom: @size100;
  .inner {
    background: #f5f5f5;
    background-image: url(../assets/images/business/mine/more.jpg);
    background-size: cover;
    color: #fff;
    padding-left: @size100;
    padding-right: @size380;
    width: @size1200;
    padding-top: 80px;
    height: @size380;
    position: relative;
    text-align: left;
    border-radius: 10px;
    overflow: hidden;
    .title {
      font-size: @size50;
      font-weight: 700;
    }
    .info {
      font-size: @font18;
      // line-height: 1.875;
      color: #fefefe;
      margin-top: @size50;
      width: 400px;
    }
    .more {
      position: absolute;
      bottom: @size40;
      right: @size40;
      width: @size200;
      min-width: 100px;
      height: @size60;
      min-height: 30px;
      border-radius: @size30;
      font-size: @size24;
      font-weight: 700;
      color: #0066dd;
      text-align: center;
      background-color: #fff;
      cursor: pointer;
      line-height: @size60;
    }
  }
}
</style>
