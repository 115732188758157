<template>
  <div class="cards">
    <ProductCardsInner :cards="cards"></ProductCardsInner>
  </div>
</template>

<script>
import icon1 from '../assets/images/business/education/icon-1.png';
import icon2 from '../assets/images/business/education/icon-2.png';
import icon3 from '../assets/images/business/education/icon-3.png';
import icon4 from '../assets/images/business/education/icon-4.png';
import ProductCardsInner from '@/components/ProductCardsInner.vue';

export default {
  name: 'EducationCards',
  components: {
    ProductCardsInner,
  },
  data() {
    return {
      cards: [
        {
          title: '学习中心',
          img: icon2,
          info: '涵盖基础职业技能、通用职业技能和专业职业技能近万课时在线精品课程及证书课程，提供持续赋能',
          href: 'https://www.coalchinajy.com/skill/home',
        },
        {
          title: '职业规划',
          img: icon1,
          info: '为学生提供职业及发展方向指导，进行行业职业测评，为学生提供指导意见清晰、完备的《职业能力测评》',
          href: 'https://www.coalchinajy.com/evaluation/home',
        },
        {
          title: '校园招聘',
          img: icon4,
          info: '为参会企业开设空中宣讲或者线上双选虚拟摊位，双方随时可进行视频面试沟通',
          href: 'https://www.coalchinajy.com/schoolRecruit/home',
        },
        {
          title: '企业招聘',
          img: icon3,
          info: '拥有丰富的企业及院校资源库，联通校企，帮助企业实现精准招聘',
          href: 'https://www.coalchinajy.com/companyRecruit/home',
        },
      ],
    };
  },
};
</script>
<style scoped lang="less">
.cards {
  width: 100%;
  position: relative;
  color: #fff;
  padding-top: @size100;
  padding-bottom: @size100;
  background: #f5f5f5;
}
.wrapper {
  width: @size1200;
  min-width: 600px;
  display: flex;
  justify-content: space-between;
  .card-item {
    width: @size285;
    min-width: 142px;
    height: @size200;
    min-height: 100px;
    border-radius: @size10;
    overflow: hidden;
    background: #ffffff;
    border: 1px solid #999999;
    text-align: center;
    padding-top: @size30;
    img {
      height: @size80;
      min-height: 40px;
      width: auto;
    }
    .title {
      font-size: @size20;
      margin-top: @size25;
      color: #333;
    }
  }
}
</style>
