<template>
  <div class="education-center">
    <Header theme="white" :currentIndex="headerIndex" :childIndex="childIndex" />
    <TopPic :img="topPic" :title="title" :info="info" :breadcrumb="breadcrumb" />
    <div class="pic-wrapper-1">
      <PicInfo :pic="infoImage1" :pItems="pItems1" />
    </div>
    <div class="pic-wrapper-2">
      <PicInfo :pic="infoImage2" :pItems="pItems2" :imgInLeft="false" />
    </div>
    <SchoolCards />
    <EducationCards2 />
    <Consult />
    <Footer />
    <div class="footer"></div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import TopPic from '@/components/TopPic.vue';
import Consult from '@/components/Consult.vue';
import PicInfo from '@/components/PicInfo.vue';
// import EducationCards from '@/components/EducationCards.vue';
import EducationCards2 from '@/components/EducationCards2.vue';
import SchoolCards from '@/components/SchoolCards.vue';
import config from '../../utils/educationCenter';

export default {
  name: 'EducationCenter',
  components: {
    Header,
    Footer,
    TopPic,
    Consult,
    PicInfo,
    EducationCards2,
    SchoolCards,
  },
  data() {
    return {
      ...config,
    };
  },
};
</script>
<style lang="less" scoped>
.pic-wrapper-1 {
  padding-top: @size60;
  padding-bottom: @size60;
  background-color: #f5f5f5;
}
.pic-wrapper-2 {
  padding-top: @size60;
  padding-bottom: @size60;
  background-color: #fff;
}
.service-wrapper {
  padding-top: @size100;
  padding-bottom: @size60;
  background-color: #f5f5f5;
}
</style>
